import React from 'react'
import NotFoundStyles from './not.found.module.scss'
import Container from '../../components/container/container.component'
import Layout from '../../components/layout/layout.component'
import { graphql, useStaticQuery } from 'gatsby'
import { useScroll } from '../../hooks/scroll.hook'

/* Update only if clean all project */
export default function NoFound() {
  const { scrollTop } = useScroll()
  //404 text
  const data = useStaticQuery(graphql`
    query {
      nodePage(field_menu_link: { eq: "/404" }) {
        title
        field_menu_link
        body {
          value
          summary
        }
        field_show_contact
        field_show_footer
        field_show_submissions_form
        field_description
        field_color_header
      }
      allMenuItems(
        filter: {
          childrenMenuItems: { elemMatch: { title: { ne: null } } }
          url: { eq: "/esteban-vicente" }
        }
      ) {
        nodes {
          id
          title
          url
          weight
          childrenMenuItems {
            weight
            url
            title
          }
        }
      }
    }
  `)

  return (
    <Layout
      title={data.nodePage.title}
      description={data.nodePage.field_description}
      secondary
      menuData={data.allMenuItems.nodes[0]}
      isMoving={scrollTop > 100}
      showFooter={data.nodePage.field_show_footer}
      showContact={data.nodePage.field_show_contact}
      isMenuWhite
      noBackgorund
    >
      <Container oneColumn>
        <div className={NotFoundStyles.wrapper}>
          <h1>{data.nodePage.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: data.nodePage.body.value }} />
        </div>
      </Container>
    </Layout>
  )
}
